import React, { useMemo } from 'react'
import { graphql, Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import { SendEnquiryBlock } from '/src/components/common'
import { removeTags, trimMetaDescription, formatDate } from '/src/util'

export const Head = ({ data }) => {
  const [product] = data.allNodeArticle.edges
  const hasBody = useMemo(() => product.node.body && product.node.body.processed ? true : false, [product.node.body.processed])

  return (
    <>
      <title>{product.node.title} | Orrcon Steel</title>
      {hasBody && <meta name="description" content={trimMetaDescription(removeTags(product.node.body.processed))} />}
      {hasBody && <meta name="og:description" content={trimMetaDescription(removeTags(product.node.body.processed))} />}
      <meta property="og:image" content="https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/og_orrcon_news.jpg" />
    </>
  )
}

const NewsContent = ({ body }) => {
  return body.map(({ processed }) => {
    return (
      <div className="body news-body mb-2" dangerouslySetInnerHTML={{
        __html: processed
      }}></div>
    )
  })
}

export default ({ data }) => {
  const [news] = data.allNodeArticle.edges

  if (!news) {
    return 'no news found.'
  }

  return (
    <Layout>
      <Row className="mt-5 mb-5">
        <Col xs={12}>
          <div className="mb-5">
            <Link className="back-to-link" to='/resources/news'>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to news
            </Link>
          </div>

          <Row>
            <Col sm={12} className="d-flex align-items-center">
              <div className="title-container">
                <h1 className="title">
                  {news.node.title}
                </h1>
                <h6 className="mt-3">{formatDate(news.node.created)}</h6>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5 mb-5">
        <Col xs={12}>
          <NewsContent {...news.node} />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={12}>
          <SendEnquiryBlock />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={12}>

        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    allNodeArticle(filter: {id: {eq: $id}}, sort: {order: DESC, fields: created}) {
      edges {
        node {
          title
          drupal_id
          id
          created
          body {
            processed
          }
          path {
            alias
          }
          relationships {
            field_image {
              uri {
                url
              }
            }
          }
        }
      }
    }
  }
`
